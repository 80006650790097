<template>
    <section class="edit-category">
        <Loader :loading="showLoader" />
        <div class="row">
            <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-title">
                        <div class="row">
                            <div class="col-12">
                                <h3 class="page-title float-left">Add Seo</h3>
                                <router-link class="float-right" to="/website-management/seo">
                                    <i class="mdi mdi-window-close font-size-18 text-dark"></i>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <form class="pt-2" @submit.prevent="addSeoPage">
                            <div class="row">
                                <div class="col-md-5">
                                    <div class="form-group">
                                        <label for="name">Url</label>
                                        <input type="text" v-model="addSeo.url" class="form-control" :class="{
                                            'is-invalid': submitted && $v.addSeo.url.$error,
                                        }" />
                                        <div v-if="submitted && !$v.addSeo.url.required" class="invalid-feedback">
                                            Url is required
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="name" class="w-100 mb-0 pb-0">Meta Title</label>
                                        <label style="font-size: 11px;">
                                            The meta title should be between 50–60 characters. &nbsp; {{ addSeo.meta_title.length }} / 60 characters
                                        </label>
                                        <input type="text" v-model="addSeo.meta_title" class="form-control"
                                            :class="{ 'is-invalid': submitted && $v.addSeo.meta_title.$error }" />
                                      
                                        <div v-if="submitted && !$v.addSeo.meta_title.required"
                                            class="invalid-feedback">
                                            Meta Title is required
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label  class="w-100 mb-0 pb-0"  for="name">Meta keyword</label>
                                        <label style="font-size: 11px;">
                                         Add comma to separate keyword
                                        </label>
                                        
                                        <input type="text" v-model="addSeo.meta_keyword" class="form-control" :class="{
                                            'is-invalid': submitted && $v.addSeo.meta_keyword.$error,
                                        }" />
                                        <div v-if="submitted && !$v.addSeo.meta_keyword.required"
                                            class="invalid-feedback">
                                            Meta Keyword is required
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-7">
                                    <div class="form-group">
                                        <label for="content" class="w-100">Meta Description</label>
                                        <label style="font-size: 11px;">
                                            The meta description should be between 50–160 characters. &nbsp;  {{ addSeo.meta_description.length }} / 160 characters
                                        </label>
                                        <textarea v-model="addSeo.meta_description" class="form-control" rows="12"
                                            :class="{ 'is-invalid': submitted && $v.addSeo.meta_description.$error }"></textarea> 
                                        <div v-if="submitted && !$v.addSeo.meta_description.required"
                                            class="invalid-feedback">
                                            Meta Description is required
                                        </div>
                                    </div>

                                </div>
                            </div>


                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group float-right mb-0 pt-3">

                                        <button class="btn btn btn-add btn-primary">Submit</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { setingsApi } from "../../../api";
import { required, minLength, maxLength } from "vuelidate/lib/validators";

import Loader from "../../../components/Loader";
export default {
    name: "add-invoice",
    components: {
        Loader,
    },
    data() {
        return {
            showLoader: false,
            submitted: false,
            addSeo: {
                url: "",
                meta_title: "",
                meta_keyword: "",
                meta_description: ""
            }
        };
    },
    validations: {
        addSeo: {
            url: { required },
            meta_title: { required, minLength: minLength(50), maxLength: maxLength(60) },
            meta_keyword: { required },
            meta_description: { required, minLength: minLength(50), maxLength: maxLength(160) },
        }
    },
    methods: {
        async addSeoPage() {
            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            this.showLoader = true;
            console.log(this.addSeo);
            const data = await setingsApi.addSeo({
                ...this.addSeo,
            });
            this.showLoader = false;
            this.$router.push("/website-management/seo");
            if (data.status == 200) {
                this.$swal({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    type: "success",
                    title: " ",
                    text: data.messages,
                });
            } else {
                this.$swal({
                    type: "error",
                    title: "Oops...",
                    text: data.messages,
                });
            }
        },
    },
};
</script>
